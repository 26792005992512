<template>
  <v-theme-provider dark>
    <base-section id="contact-us" class="primary" space="40">
      <v-container>
        <v-row justify="center">
          <v-col cols="10">
            <base-title
              :space="$vuetify.breakpoint.mdAndUp ? 0 : 4"
              :title="$t('contactUs.text1')"
            />

            <base-body space="0">
              <span class="white--text">
                {{ $t("contactUs.text2") }}
              </span>
            </base-body>
          </v-col>

          <v-col cols="12" md="2">
            <kdw-btn
              :block="$vuetify.breakpoint.smAndDown"
              color="white"
              href="mailto:contact@kidiwidigital.com?subject=Question"
              large
              outlined
              target="_blank"
            >
              {{ $t("button.contactUs") }}
            </kdw-btn>
          </v-col>
        </v-row>
      </v-container>
    </base-section>
  </v-theme-provider>
</template>

<script>
export default {
  name: "SectionContactUs",
};
</script>
